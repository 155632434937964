<template>
  <div>
    <Loader />
    <section class="sign-in-page">
      <div class="bg-white p-0">
        <div class="row no-gutters">
          <div class="col-sm-6 text-center">
            <div class="sign-in-detail text-white" :style="'background: url('+bgImageURL+') no-repeat 0 0; background-size: cover;'">
              <router-link :to="{ name: 'default.default' }">
                <v-btn
                  color="primary"
                  class="theHome"
                >
                  Home
                </v-btn>
              </router-link>
              <a class="sign-in-logo mb-5" href="#"><img :src="logo" class="img-fluid" alt="logo"></a>
              <Swiper :options="options">
                <SwiperSlide class="item">
                  <img :src="require('../../assets/images/login/12booky-appointment.jpg')" class="img-fluid mb-4" alt="logo">
                  <h4 class="mb-1 text-white">{{ $t('auth.manage_calendar') }}</h4>
                  <p>{{ $t('auth.about_calendar') }}</p>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div class="col-sm-6 sign-in-12booky">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Loader from '../../components/core/loader/Loader'
import logo from '../../assets/images/12bookylogo.png'
import { core } from '../../config/pluginInit'
import bgImage from '../../assets/images/login/login-2.jpg'
import Swiper from '../../components/core/slider/Swiper'
import SwiperSlide from '../../components/core/slider/SwiperSlide'
export default {
  name: 'CustomerAuthLayout',
  components: {
    Loader,
    Swiper,
    SwiperSlide
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      options: {
        slidesPerView: 1,
        loop: true
      },
      logo: logo,
      bgImageURL: bgImage
    }
  }
}
</script>
<style>
  @import url("../../assets/css/custom.css");
  .theHome{
    position: fixed;
    top: 10px;
    left: 10px;
    background: #1976d2 !important;
  }
</style>
